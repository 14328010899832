<template>
  <v-card outlined>
    <v-card-text>
      <v-data-table
        flat 
        class="mb-3 title"
        loader-height="1"
        hide-default-footer
        :loading="loading"
        :headers="headers"
        :items="products.data"
      >
        <template v-slot:item.description="{ item }">
          <v-menu
            open-on-hover
            right
            offset-x
            max-width="250"
          >
            <template v-slot:activator="{ on, attrs }">
              <div 
                class="text-truncate" 
                style="max-width: 380px"
                v-bind="attrs"
                v-on="on"
              >
                {{ item.description }}
              </div>
            </template>

            <v-card color="secondary lighten-1">
              <v-card-text>
                <p class="ma-0 white--text">
                  {{ item.description }}
                </p>
              </v-card-text>
            </v-card>
          </v-menu>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn
            dark
            label
            small
            color="primary"
            class="caption ttn"
            @click="$emit('edit', item)"
          >
            Edit
          </v-btn>
        </template>
      </v-data-table>

      <app-pagination
        v-if="products.meta"
        :meta="products.meta"
        @pageChanged="pageChanged"
      ></app-pagination>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data () {
    return {
      page: 1,
      loading: true,
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Description', value: 'description', },
        // { text: 'Actions', value: 'actions' },
      ],
    }
  },

  computed: {
    ...mapGetters({
      products: 'getInsuranceProducts'
    })
  },

  methods: {
    ...mapActions([
      'setInsuranceProducts'
    ]),

    loadInsuranceProducts () {
      this.setInsuranceProducts({
        page: this.page
      })
        .finally(() => {
          this.loading = false
        })
    },

    pageChanged (page) {
      this.page = page
      this.loading = true
      this.loadInsuranceProducts()
    },
  },

  mounted () {
    this.loadInsuranceProducts()
  }
}
</script>